import { VulcanPrecarityAnahLevelValues, VulcanPrecarityLevelValues, VulcanWorkTypeValues } from '@prc-lib-core';
import { AnswserKeys, HouseholdIncome, WorksType } from '@prc-serenity-models/keys.model';
import { AnswsersWorksState } from '@prc-serenity-store/reducers/answers-works.reducer';
import { AnswersState } from '@prc-serenity-store/reducers/answers.reducer';

export class VulcanUtils {
  static getWorksTypes(answers: AnswersState, answsersWorks: AnswsersWorksState): VulcanWorkTypeValues[] {
    const isWorkInsulaton = answers[AnswserKeys.WorksType] === WorksType.Insulation;
    const worksTypes = (isWorkInsulaton) ? [answsersWorks[AnswserKeys.InsulationType]] : [answers[AnswserKeys.WorksType]];
    return worksTypes as VulcanWorkTypeValues[];

    // if (answers[AnswserKeys.WorksType] === WorksType.AirWaterHeatPump) {
    //   worksTypes.push(VulcanWorkTypeValues.AirWaterHeatPump);
    // }

    // if (answers[AnswserKeys.WorksType] === WorksType.PhotovoltaicPanel) {
    //   worksTypes.push(VulcanWorkTypeValues.PhotovoltaicPanel);
    // }

    // if (answers[AnswserKeys.WorksType] === WorksType.Insulation) {
    //   const workType = answsersWorks[AnswserKeys.InsulationType];
    //   if (workType) {
    //     worksTypes.push(workType);
    //   }
    // }

    // return worksTypes;
  }

  static getPrecarityLevel(income: HouseholdIncome): VulcanPrecarityLevelValues {
    const PRECARITY_LEVEL_MAPPER = {
      [HouseholdIncome.VeryModest]: VulcanPrecarityLevelValues.VeryModest,
      [HouseholdIncome.Modest]: VulcanPrecarityLevelValues.Modest,
      [HouseholdIncome.Standard]: VulcanPrecarityLevelValues.Standard,
      [HouseholdIncome.High]: VulcanPrecarityLevelValues.Standard,
    };

    return PRECARITY_LEVEL_MAPPER[income];
  }

  static getPrecarityAnahLevel(income: HouseholdIncome): VulcanPrecarityAnahLevelValues {
    const PRECARITY_ANAH_LEVEL_MAPPER = {
      [HouseholdIncome.VeryModest]: VulcanPrecarityAnahLevelValues.VeryModest,
      [HouseholdIncome.Modest]: VulcanPrecarityAnahLevelValues.Modest,
      [HouseholdIncome.Standard]: VulcanPrecarityAnahLevelValues.Intermediate,
      [HouseholdIncome.High]: VulcanPrecarityAnahLevelValues.HighIncome,
    };

    return PRECARITY_ANAH_LEVEL_MAPPER[income];
  }
}
